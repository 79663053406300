<template>
  <v-row class="ma-0 mobile" justify="center">
    <v-col cols="12" class="px-1">
      <v-card elevation="3" min-height="550px" class="pa-5 plan-card card-outter flex d-flex flex-column">
        <v-carousel v-model="carouselPosition" hide-delimiter-background :show-arrows="false" height="550px">
          <v-carousel-item
            v-for="(plan, index) in activePlans"
            :key="index"
            :dark="$vuetify.theme.dark"
            :light="!$vuetify.theme.dark"
          >
            <v-card-text>
              <v-row class="text-h5 text--tway-violet pb-3 font-weight-bold" justify="start">
                <div>{{ firstToUpperCase(plan.name) }}</div>
              </v-row>
              <v-row class="text-h5 font-weight-bold roboto" justify="start">
                <div v-if="plan.name === 'SMART' || plan.name === 'MANAGER'" style="width: 100%">
                  USD
                  {{ plan.name != "MANAGER" ? Math.round(plan.size.price) : plan.size.price }}
                  <span class="subtitle-2" style="align-self: end"> / cobro mensual </span>
                </div>
                <div
                  class="subtitle-1 grey--text text--darken-1"
                  v-if="plan.name === 'SMART' || plan.name === 'MANAGER'"
                >
                  <strong>
                    USD
                    {{ plan.size.totalPrice }}
                  </strong>
                  <span class="subtitle-2" style="align-self: end"> total anual </span>
                </div>
                <div v-else>USD {{ plan.size.price }}</div>
              </v-row>
              <v-row class="subtitle-2 mb-4 roboto font-wight-bold" justify="start">
                <strong>
                  <span class="text--tway-violet">{{
                    plan.size.period !== "ONCE" ? "Suscripción anual" : "Paga una sola vez"
                  }}</span>
                </strong>
              </v-row>

              <v-row>
                <v-col cols="12" class="mt-n1 pl-0 py-1">
                  <span class="text-t roboto"> {{ plan.size.users }} usuarios </span>
                </v-col>
                <v-col cols="12" class="mt-n1 pl-0 py-1">
                  <span class="text-t roboto"> {{ plan.size.userSurveys }} diagnósticos </span>
                </v-col>
              </v-row>

              <v-row class="my-5">
                <v-btn
                  rounded
                  :disabled="plan.id == subscription.plan.id"
                  block
                  color="success"
                  class="roboto"
                  :to="{
                    name: 'subscribe',
                    params: { plan: plan.id },
                  }"
                >
                  Seleccionar
                </v-btn>
              </v-row>

              <v-divider></v-divider>

              <v-row class="mt-4">
                <div class="text--primary mx-n5 pl-5">
                  <div
                    style="display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start"
                    class="mb-1"
                    v-for="(feature, featureIndex) in plan.features"
                    :key="featureIndex"
                  >
                    <v-img
                      :src="require('@/assets/img/check-2.svg')"
                      class="rounded mr-2"
                      min-width="12px"
                      max-width="12px"
                      style="align-self: center"
                    />
                    <span v-html="feature.description" class="text-t roboto pr-2" />
                  </div>
                </div>
              </v-row>
            </v-card-text>
          </v-carousel-item>
        </v-carousel>
        <div
          @click="carouselPosition--"
          class="v-window__prev"
          style="left: -30px; border-top-left-radius: none; border-top-right-radius: none"
        >
          <button type="button" class="v-btn v-btn--icon v-btn--round theme--dark v-size--default">
            <span class="v-btn__content">
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-left theme--dark" style="font-size: 36px">
              </i>
            </span>
          </button>
        </div>
        <div
          @click="carouselPosition++"
          class="v-window__next"
          style="right: -30px; border-top-left-radius: none; border-top-right-radius: none"
        >
          <button
            type="button"
            class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
            aria-label="Visual siguiente"
          >
            <span class="v-btn__content">
              <i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-chevron-right theme--dark"
                style="font-size: 36px"
              >
              </i>
            </span>
          </button>
        </div>
        <div></div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    activePlans: {
      type: Array,
      default: () => [],
    },
    company: {
      type: Object,
      default: () => {},
    },
    subscription: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      carouselPosition: 0,
    };
  },
  methods: {
    firstToUpperCase: function (value) {
      if (typeof value !== "string") {
        return value;
      }
      return value[0].toUpperCase() + value.toLowerCase().slice(1);
    },
  },
};
</script>

<style>
.v-carousel__controls .v-item-group .v-carousel__controls__item {
  color: #7c7c7c;
}
.v-carousel__controls .v-item-group .v-item--active {
  color: #119a24;
}
.v-carousel__controls .v-item-group .v-carousel__controls__item::before {
  color: #119a24;
}
</style>
