<template>
  <v-row class="ma-0 desktop" justify="center">
    <v-col v-for="(plan, index) in activePlans" :key="index" class="ma-0 d-flex flex-column" cols="12" md="4">
      <v-card min-height="500px" class="pa-5 plan-card card-outter flex d-flex flex-column">
        <v-card-text>
          <v-row class="text-h5 text--tway-violet pb-3 font-weight-bold" justify="start">
            <div>{{ firstToUpperCase(plan.name) }}</div>
          </v-row>
          <v-row class="text-h5 font-weight-bold black--text roboto" justify="start">
            <div v-if="plan.size.period !== 'ONCE'" style="width: 100%">
              USD
              {{ plan.name != "MANAGER" ? Math.round(plan.size.price) : plan.size.price }}
              <span class="subtitle-2" style="align-self: end"> / cobro mensual </span>
            </div>
            <div class="subtitle-1 grey--text text--darken-1" v-if="plan.size.period !== 'ONCE'">
              <strong>
                USD
                {{ plan.size.totalPrice }}
              </strong>
              <span class="subtitle-2" style="align-self: end"> total anual </span>
            </div>
            <div v-else class="mb-7">USD {{ plan.size.price }}</div>
          </v-row>
          <v-row class="subtitle-2 mb-4 roboto font-wight-bold" justify="start">
            <strong>
              <span class="text--tway-violet">{{
                plan.size.period !== "ONCE" ? "Suscripción anual" : "Paga una sola vez"
              }}</span>
            </strong>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-n1 pl-0 py-1">
              <span class="text-t roboto"> {{ plan.size.users }} usuarios </span>
            </v-col>
            <v-col cols="12" class="mt-n1 pl-0 py-1">
              <span class="text-t roboto"> {{ plan.size.userSurveys }} diagnósticos </span>
            </v-col>
          </v-row>

          <v-row class="my-5">
            <v-btn
              rounded
              :disabled="plan.id == subscription.plan.id"
              block
              color="success"
              class="roboto"
              :to="{
                name: 'subscribe',
                params: { plan: plan.id },
              }"
            >
              Seleccionar
            </v-btn>
          </v-row>

          <v-divider></v-divider>

          <v-row class="mt-4">
            <div class="text--primary mx-n5 pl-5">
              <div
                style="display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start"
                class="mb-6"
                v-for="(feature, index1) in plan.features"
                :key="index1"
              >
                <v-img
                  :src="require('@/assets/img/check-2.svg')"
                  class="rounded mr-2"
                  min-width="12px"
                  max-width="12px"
                  style="align-self: center"
                />
                <span v-html="feature.description" class="text-t roboto pr-2" />
              </div>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    activePlans: {
      type: Array,
      default: () => [],
    },
    company: {
      type: Object,
      default: () => {},
    },
    subscription: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    firstToUpperCase: function (value) {
      if (typeof value !== "string") {
        return value;
      }
      return value[0].toUpperCase() + value.toLowerCase().slice(1);
    },
  },
};
</script>

<style></style>
