<template>
  <div>
    <Loader :visible="isLoading" />
    <v-container fluid v-if="!isLoading">
      <v-row class="mb-4 mt-4 tway-header-1 ma-0 pa-0 text-center">
        <v-col class="ma-0 pa-0" cols="12">
          <span class="text-capitalize">{{ getUmapiUser.firstName }}</span
          >,
          <span>{{
            getCompany.type === "CLIENT"
              ? "¿Necesitas Transformación Digital?"
              : "¿Quieres conectar con nuevos Clientes?"
          }}</span>
        </v-col>
      </v-row>
      <div class="text-subtitle-1 text-md-h6 mb-8 roboto text-center">
        Selecciona el plan que mejor se adapte a tu empresa para activar, acelerar o facilitar el camino de la
        Transformación Digital.
      </div>
      <desktop v-if="desktop" :active-plans="activePlans" :company="getCompany" :subscription="getSubscription" />
      <mobile v-if="mobile" :active-plans="activePlans" :company="getCompany" :subscription="getSubscription" />
      <v-row justify="center" v-if="getSubscription.payments != undefined && getSubscription.payments.length > 0">
        <v-btn rounded color="tway-violet" class="button-text white--text roboto mt-6" :to="{ name: 'my-payment' }">
          VER MIS PAGOS
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import desktop from "@/components/plans/desktop";
import mobile from "@/components/plans/mobile";

export default {
  name: "Plans",
  components: {
    Loader,
    desktop,
    mobile,
  },
  data: () => ({
    isLoading: true,
  }),
  async created() {
    const company = await this.setCompanyProfile();
    await Promise.all([this.setUmapiUserData(), this.setPlansByType(company.type), this.setSubscription()])
      .then(() => (this.isLoading = false))
      .catch((error) => console.log(error));
  },
  methods: {
    ...mapActions("UserStore", ["setUmapiUserData"]),
    ...mapActions("CompanyStore", ["setCompanyProfile"]),
    ...mapActions("PlanStore", ["setPlansByType"]),
    ...mapActions("subscription", ["setSubscription"]),
  },
  computed: {
    ...mapGetters("UserStore", ["getUmapiUser"]),
    ...mapGetters("CompanyStore", ["getCompany"]),
    ...mapGetters("PlanStore", ["getActivePlans"]),
    ...mapGetters("subscription", ["getSubscription"]),
    activePlans() {
      if (this.getSubscription.plan != undefined) {
        if (this.getCompany.type == "CLIENT" && this.getSubscription.plan.name == "NOPLAN") {
          const activePlans = this.getActivePlans.filter((e) => e.name !== "MANAGER");
          return activePlans;
        } else {
          return this.getActivePlans;
        }
      }
      return [];
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
<style lang="scss" scoped>
.plan-card {
  border-radius: 15px;
}

.disable-events {
  pointer-events: none;
}

.black-text {
  color: #333333;
}

.arrow-btn-left {
  position: absolute;
  left: -7%;
  height: 46px !important;
  width: 46px !important;
}

.arrow-btn-right {
  position: absolute;
  right: -7%;
  height: 46px !important;
  width: 46px !important;
}

.mobile-plan-card-container {
  margin-top: 1%;
  margin-left: 8%;
  margin-right: 8%;
}

.mobile-plan-card {
  padding-top: 8%;
  padding-bottom: 8%;
  padding-left: 8%;
  padding-right: 8%;
  border-radius: 40px;
}

.mobile-plan-card p {
  margin-top: 4%;
  margin-bottom: 4%;
  text-align: left;
  margin-left: 10%;
}

.mobile-plan-card .v-btn {
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.promo-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;

  text-align: center;

  color: #000000;
}

.end-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Tway / Gris Oscuro */

  color: #333333;
}

.card-outter {
  position: relative;
  padding-bottom: 50px;
}

.card-actions {
  position: absolute;
  left: 18.38%;
  right: 18.38%;
  top: 90.02%;
  bottom: 5.99%;
}

.card-actions-button {
  position: absolute;
  left: 10.38%;
  right: 10.38%;
  top: 90.02%;
  bottom: 5.99%;
}

.border-card {
  border: 1px solid #7319d5;
}
</style>
